<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div class="w-100">
          <h1>{{ $t("Monthly net income") }}</h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "In the previous step we have determined your gross income. Some landlords require you to share net income.Please share your most recent net income which can be found on your payslip."
              )
            }}
          </p>
          <div class="d-flex">
            <b-spinner
              class="mx-auto"
              large
              label="Spinning"
              variant="primary"
              v-if="getLoadings.get_tenant"
            >
            </b-spinner>
          </div>
          <div class="mt-3" v-if="!getErrors.tenant">
            <b-form-group v-slot="{ ariaDescribedby }" v-if="!getLoadings.get_tenant">
              <TextInput
                name=""
                type="number"
                v-model="form.net_income"
                label="Monthly net income"
                :aria-describedby="ariaDescribedby"
                :masked="true"
                :mask="[
                  {
                    mask: 'num',
                    blocks: {
                      num: {
                        mask: Number, // enable number mask
                        scale: 2,
                        signed: false,
                        //thousandsSeparator: '.',
                        padFractionalZeros: false,
                        normalizeZeros: true,
                        //radix: ',',
                        min: 0,
                        max: 99999999,
                      },
                    },
                  },
                ]"
                defaultValue=""
                prepend="€"
              />
            </b-form-group>
          </div>
          <div class="alert alert-danger mb-3" v-if="getErrors.tenant">
            {{ getErrors.tenant }}
          </div>
          <div class="w-100">
            <button
              class="btn btn-primary btn-block"
              :disabled="!(form && form.net_income) || getLoadings.tenant"
              @click="onSubmit"
            >
              <b-spinner
                v-if="getLoadings.tenant"
                small
                label="Spinning"
                variant="white"
                class="mr-1"
              />
              {{ $t("Next step") }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <!-- <img src="../../../assets/images/nordigen/nordigen_logo.png" alt=""> -->
          <!-- <img src="../../../assets/images/expat/step8.png" alt=""> -->
          <RightPoints active="5" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import RightPoints from "./RightPoints.vue";
import TextInput from "../../../components/ui/form/controls/TextInput.vue";

export default {
  data() {
    return {
      error: "",
      form: {
        net_income: "",
      },
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    RightPoints,
    TextInput,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("nordigen", ["transactions"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
  },
  async created() {
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      await this.getTenant(tenant_id);
      this.form.net_income = this.tenant.net_income;
    }
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    async onSubmit() {
      let net_income = this.form.net_income;
      net_income = net_income ? ("" + net_income).replace(".", "") : net_income;
      await this.updateTenant({ net_income });
      this.$router.push({ name: "OnboardingSuccess" });
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 450px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
